import React from 'react'
import fb from "../assets/icons/fb.png";
import insta from "../assets/icons/insta.png";
import link from "../assets/icons/link.png";
import yt from "../assets/icons/yt.png";
import twiter from "../assets/icons/twiter.png";
import "../styles/component/FooterTop.css"
const FooterTop = () => {
  return (
    <div className="footer-top">
    <p>Follow GameStart on Twitter and we will keep you updated!</p>
    <div className="icons-list">
      <span className="circle">
        <img src={fb} alt="" />
      </span>
      <span className="circle">
      <img src={insta} alt="" />
      </span>
      <span className="circle">
      <img src={link} alt="" />
      </span>
      <span className="circle">
      <img src={twiter} alt="" />

      </span>
      <span className="circle">
      <img src={yt} alt="" />
      </span>
    </div>
  </div>
  )
}

export default FooterTop

import React, { useState } from 'react'
import Button from '../../components/Button'
import SideBar from '../../components/SideBar'
import Notifications from '../../components/Notifications'
import DashboardHeader from '../../components/DashboardHeader'

export default function NotificationSettings() {
    return (
        <div>
            <div>
                <DashboardHeader />
            </div>
            <div className="absolute top-0 left-0 lg:left-6">
                <SideBar></SideBar>
            </div>
            <div className="NotificationSetting ml-[20vw] sm:ml-[10vw] mr-[8vw]">
                <h1 className=" text-black text-[30px] sm:text-[40px] font-medium">Notification Settings </h1>
                <div className="flex gap-2 justify-end">
                    <Button text="Discard Changing" bgcolor="white" textcolor="indigo-500" />
                    <Button text="Save Changing" bgcolor="indigo-500" textcolor="white" />
                </div>
                <div className="mt-8 sm:mt-[71px]">
                    <h1 className='text-zinc-800 text-lg font-semibold leading-7'>Notifications from us</h1>
                    <p className='text-slate-500 text-xs font-medium leading-[18px]'>Receive the latest news, updates, and industry tutorials from us. </p>
                </div>
                <div className="space-y-4 mt-[26px]">
                    <CheckboxWithLabel
                        label="News and updates"
                        description="News about product and feature updates."
                    />
                    <CheckboxWithLabel
                        label="Tips and tutorials"
                        description="News about product and feature updates."
                    />
                    <CheckboxWithLabel
                        label="User research"
                        description="News about product and feature updates."
                    />
                </div>
                <div className="mt-[26px]">
                    <h1 className='text-zinc-800 text-lg font-semibold leading-7'>Comments</h1>
                    <p className='text-slate-500 text-xs font-medium leading-[18px]'>These are notifications for comments on your posts and replies to your comments</p>
                </div>
                <div className="space-y-4 mt-[28px]">
                    <RadioWithLabel
                        label="Do not notify me"
                    />
                    <RadioWithLabel
                        label="Mentions only"
                        description="Only notify me if I’m mentioned in a comment."
                    />
                    <RadioWithLabel
                        label="All comments"
                        description="Notify me for all comments on my posts."
                    />
                </div>
            </div>
        </div>
    )
}
const CheckboxWithLabel = ({ label = "", description = "" }) => {
    return (
        <div className="flex items-center gap-4">
            <div className="">
                <input name={label} className='focus:outline-none rounded focus:border-none w-6 h-6' type="checkbox" />
            </div>
            <div className="">
                <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
                <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
            </div>
        </div>
    );
};
const RadioWithLabel = ({ label = "", description = "" }) => {
    return (
        <div className="flex items-center gap-4">
            <div className="">
                <input name={label} className='focus:outline-none focus:border-none w-6 h-6' type="radio" />
            </div>
            <div className="">
                <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
                <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
            </div>
        </div>
    );
};
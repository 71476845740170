import './App.css';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
} from "react-router-dom";

import Home from "./pages/Home"
import NewCompaign from "./pages/NewCompaign"
import Explore from "./pages/Explore"
import Login from "./pages/auth/Login"
import Faq from "./pages/Faq"
import SignUp from "./pages/auth/SignUp"
import Payment from "./pages/dashboard/PaymentSetting"
import DashboardHome from "./pages/dashboard/DashboardHome"

import MyDashboard from './pages/dashboard/MyDashboard';
import Administration from './pages/dashboard/Administration';
import Messages from './pages/dashboard/Messages';
import Video from './pages/Video';
import NotificationSettings from './pages/dashboard/NotificationSettings';
import DashboardCampaign from './pages/dashboard/DashboardCampaign';
import ProfileSetting from './pages/dashboard/ProfileSetting';
import PaymentSetting from './pages/dashboard/PaymentSetting';
import PrivacyPolicy from './pages/dashboard/PrivacyPolicy';
import TermsOfService from './pages/dashboard/TermsOfService';


function App() {
  return (


    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/campaign" element={<NewCompaign />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/video" element={<Video />} />

        <Route path="/home-dashboard" element={<DashboardHome />} />
        <Route path="/mydashboard" element={<MyDashboard />} />
        <Route path="/admindashboard" element={<Administration />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/notificationsettings" element={<NotificationSettings />} />
        <Route path="/dashboardcompaign" element={<DashboardCampaign />} />
        <Route path="/profilesettings" element={<ProfileSetting />} />
        <Route path="/paymentsetting" element={<PaymentSetting />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />

      </Routes>
    </BrowserRouter>


  );
}

export default App;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../styles/component/Hamburger.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import CloseIcon from '@mui/icons-material/Close';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import logo from "../assets/images/logo.png"

const HamburgerMenu = ({ setHamburgerMenuOpen,setIsShowLogin,isHamburgerMenuOpen }) => {
  const [isShowLogin,setIsShowLog]=useState(false)
  return (
    <div className={isHamburgerMenuOpen?"hamburgerMenu hamActive":"hamburgerMenu"}>
        <div className="closeBtn" onClick={()=>setHamburgerMenuOpen(false)}>
        <CloseIcon sx={{fontSize:"34px"}}/>
        </div>
      <div className="Logo">
        <Link to="/">
        {/* <h6 className="logo-text">GameStart</h6> */}

        <img src={logo} alt="" className="logo-text" />
        </Link>
      </div>
      <div className="Menu">
        <ul>
          <Link className="item" to="/" onClick={()=>setHamburgerMenuOpen(false)}><li >Home</li></Link>
          <Link className="item" to="/explore"onClick={()=>setHamburgerMenuOpen(false)}><li >Explore</li></Link>
       
          <Link className="item" to="/campaign"onClick={()=>setHamburgerMenuOpen(false)}><li >New Campaign</li></Link>
          <Link className="item" to="/faq"onClick={()=>setHamburgerMenuOpen(false)}><li >FAQ</li></Link>
          <Link className="item" to="/video"onClick={()=>setHamburgerMenuOpen(false)}><li >Mobile App Video</li></Link>
          {/* <Link className="item" to="/termofuse"onClick={()=>setHamburgerMenuOpen(false)}><li >Terms Of Use</li></Link>
          <Link className="item" to="/contactus"onClick={()=>setHamburgerMenuOpen(false)}><li >Contact Us</li></Link> */}

        </ul>
      </div>
      <div className="BottomHeaderLast">
        <ul>
         <Link to="/login">
         <li onClick={() =>{
                setHamburgerMenuOpen(false)
             setIsShowLog((pre) => !pre)
          
          }}>Log In</li>
         </Link>
          <div className="rightLine"></div>
          <Link to="/signup"onClick={()=>setHamburgerMenuOpen(false)}>
            <li>Sign Up</li>
          </Link>
        </ul>
      </div>

      <div className="SocialIconsContainer">
          <span className="iconBox">
            <FacebookOutlinedIcon
              style={{ fontSize: "1.2em" }}
              className="socialIcon"
            />
          </span>
          <span className="iconBox">
            <InstagramIcon
              style={{ fontSize: "1.2em" }}
              className="socialIcon"
            />
          </span>
          <span className="iconBox">
            <TwitterIcon style={{ fontSize: "1.2em" }} className="socialIcon" />
          </span>
          <span className="iconBox">
            <LinkedInIcon
              style={{ fontSize: "1.2em" }}
              className="socialIcon"
            />
          </span>
          <span className="iconBox">
            <YouTubeIcon style={{ fontSize: "1.2em" }} className="socialIcon" />
          </span>
        </div>
        {/* {isShowLogin && <Login setIsShowLogin={setIsShowLog} isShowLogin={isShowLogin} setHamburgerMenuOpen={setHamburgerMenuOpen}/>} */}
    </div>
    
  );
};

export default HamburgerMenu;

import React from "react";
import Header from "../components/Header";
import appgames from "../assets/images/app_games_icon.png";
import esports from "../assets/images/esports_icon.png";
import games from "../assets/images/games_icon.png";
import maps from "../assets/images/mods_maps_addons_icons.png";
import socialgaming from "../assets/images/social_games.png";
import gamingfear from "../assets/images/gaming_gear_icon.png";
import otherGaming from "../assets/images/other_gaming_icon.png";
import boardGames from "../assets/images/board_games_icon.png";

import section4Img from "../assets/images/section4Img.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from "@mui/icons-material/Search";
import vector from "../assets/icons/Vector.png";
import categoryDetail from "../assets/images/category-detail-img.png";

import "../styles/Explore.css";
import ProgressBar from "../components/ProgressBar";
import FooterTop from "../components/FooterTop";
import Footer from "../components/Footer";
const Explore = () => {
  return (
    <>
      <div className="main-section-explore">
        <Header />
        <div className="explore-mid-text">
          <div className="box1">
            <p className="main-heading-text">
              {" "}
              Explore new <span>games</span> and <br />
              <span>gaming projects</span>
            </p>
            {/* <img src={vector} alt="" className="vector" /> */}
          </div>
          <div className="box3">
            <div className="search-label">
              <SearchIcon className="search-icon" />
              <input
                type="search"
                id="search"
                className="search-input"
                placeholder="Search for campaign"
              />
            </div>
          </div>
        </div>
        <div className="explore-end-text">
          <p>
       Categories
          </p>

          <button className="featured-btn">
  Featured <ArrowDropDownIcon className="arrow-icon" />
</button>
        </div>
      </div>

      <div className="explore-categories">
        <div className="box">
          <img src={games} alt="" className="box-img" />
          <p className="box-text">Games</p>
        </div>
        <div className="box" style={{backgroundColor: "#020830", color:"#ffffff"}} >
        <img src={appgames} alt="" className="box-img" />
          <p className="box-text">App Games</p>
        </div>
        <div className="box">
        <img src={socialgaming} alt="" className="box-img" />
          <p className="box-text">Social Gaming</p>
        </div>

        <div className="box">
        <img src={gamingfear} alt="" className="box-img" />
          <p className="box-text">Gaming Gear</p>
        </div>

        <div className="box">
        <img src={esports} alt="" className="box-img" />
          <p className="box-text">Esports</p>
        </div>

        <div className="box">
        <img src={boardGames} alt="" className="box-img" />
          <p className="box-text">Board Games</p>
        </div>
        <div className="box">
        <img src={maps} alt="" className="box-img" />
          <p className="box-text">MODS/MAPS/ADDONS</p>
        </div>
        <div className="box other-gaming">
  
          <p className="box-text">Other Gaming</p>
        </div>
      </div>

      <div className="explore-category-detail">
        <div className="detail-box">
          <img src={categoryDetail} alt="" className="top-head" />
          <div className="bottom-detail">
            <p className="bottom-head">
            Start your own campaign
            </p>
            <ProgressBar/>
            <div className="bottom-end-line">
              <p className="large-size">
              $0.00 USD
              </p>
              <p className="large-size">0%</p>
            </div>
            <div className="bottom-end-line">
              <p className="small-size">Pledged</p>
              <p className="small-size">Funded</p>
            </div>
          </div>
        </div>
        <div className="detail-box">
          <img src={categoryDetail} alt="" className="top-head" />
          <div className="bottom-detail">
            <p className="bottom-head">
            Start your own campaign
            </p>
            <ProgressBar/>
            <div className="bottom-end-line">
              <p className="large-size">
              $0.00 USD
              </p>
              <p className="large-size">0%</p>
            </div>
            <div className="bottom-end-line">
              <p className="small-size">Pledged</p>
              <p className="small-size">Funded</p>
            </div>
          </div>
        </div>
        <div className="detail-box">
          <img src={categoryDetail} alt="" className="top-head" />
          <div className="bottom-detail">
            <p className="bottom-head">
            Start your own campaign
            </p>
            <ProgressBar/>
            <div className="bottom-end-line">
              <p className="large-size">
              $0.00 USD
              </p>
              <p className="large-size">0%</p>
            </div>
            <div className="bottom-end-line">
              <p className="small-size">Pledged</p>
              <p className="small-size">Funded</p>
            </div>
          </div>
        </div>
        <div className="detail-box">
          <img src={categoryDetail} alt="" className="top-head" />
          <div className="bottom-detail">
            <p className="bottom-head">
            Start your own campaign
            </p>
            <ProgressBar/>
            <div className="bottom-end-line">
              <p className="large-size">
              $0.00 USD
              </p>
              <p className="large-size">0%</p>
            </div>
            <div className="bottom-end-line">
              <p className="small-size">Pledged</p>
              <p className="small-size">Funded</p>
            </div>
          </div>
        </div>
        <div className="detail-box">
          <img src={categoryDetail} alt="" className="top-head" />
          <div className="bottom-detail">
            <p className="bottom-head">
            Start your own campaign
            </p>
            <ProgressBar/>
            <div className="bottom-end-line">
              <p className="large-size">
              $0.00 USD
              </p>
              <p className="large-size">0%</p>
            </div>
            <div className="bottom-end-line">
              <p className="small-size">Pledged</p>
              <p className="small-size">Funded</p>
            </div>
          </div>
        </div>
        <div className="detail-box">
          <img src={categoryDetail} alt="" className="top-head" />
          <div className="bottom-detail">
            <p className="bottom-head">
            Start your own campaign
            </p>
            <ProgressBar/>
            <div className="bottom-end-line">
              <p className="large-size">
              $0.00 USD
              </p>
              <p className="large-size">0%</p>
            </div>
            <div className="bottom-end-line">
              <p className="small-size">Pledged</p>
              <p className="small-size">Funded</p>
            </div>
          </div>
        </div>
      </div>

      <FooterTop/>
      <Footer/>
    </>
  );
};

export default Explore;

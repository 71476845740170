import React, { useState } from 'react'
import Search from '../components/Search'
import Avatar from '../components/Avatar'
import { Link } from "react-router-dom";
import bell from '../assets/icons/bell.png'
import MenuIcon from "@mui/icons-material/Menu";
import Notifications from '../components/Notifications'

export default function DashboardHeader() {

    const [showNotification, setShowNotification] = useState(false);
    const openNotification = () => {
        setShowNotification(true);
    };

    const closeNotification = () => {
        console.log("close")
        setShowNotification(false);
    };


    return (
        <>
            <div className='md:flex justify-between items-center sm:my-4 my-8 '>
                <div className=""></div>
                <nav className='ml-20 lg:ml-16 xl:ml-96 mb-4 sm:mb-0'>
                    <div className='grid grid-cols-2 lg:flex lg:gap-4 gap-y-2'>
                        <Link to="">
                            <li className='text-neutral-950 text-lg font-normal leading-none'>Explore</li>
                        </Link>
                        <Link to="">
                            <li className='text-neutral-950 text-lg font-normal leading-none'>New Campaign</li>
                        </Link>
                        {/* <Link to="">
                            <li className='text-neutral-950 text-lg font-normal'>FAQ</li>
                        </Link> */}
                        <Link to="/privacypolicy">
                            <li className='text-neutral-950 text-lg font-normal leading-none'>Privacy Policy</li>
                        </Link>
                        <Link to="/termsofservice">
                            <li className='text-neutral-950 text-lg font-normal leading-none'>Terms of Service</li>
                        </Link>
                    </div>
                </nav>
                <div className="ml-12 sm:ml-0 flex justify-center space-x-1 xl:space-x-4 items-center sm:mr-5 lg:mr-10">
                    <div onClick={openNotification}>
                        <img src={bell} alt="bell" />
                    </div>
                    <Search></Search>
                    <Avatar></Avatar>
                </div>
            </div>
            {showNotification &&
                <Notifications closeNotification={closeNotification} />
            }
        </>

    )
}

import React from "react";
import Header from "../components/Header";
import "../styles/Home.css";
import vector from "../assets/icons/Vector.png";
import appgames from "../assets/images/app_games_icon.png";
import esports from "../assets/images/esports_icon.png";
import games from "../assets/images/games_icon.png";
import maps from "../assets/images/mods_maps_addons_icons.png";
import socialgaming from "../assets/images/social_games.png";
import section4Img from "../assets/images/section4Img.png";
import gamingfear from "../assets/images/gaming_gear_icon.png";
import otherGaming from "../assets/images/other_gaming_icon.png";
import boardGames from "../assets/images/board_games_icon.png";
import SearchIcon from "@mui/icons-material/Search";

import Footer from "../components/Footer";
import FooterTop from "../components/FooterTop";
const Home = () => {
  return (
    <>
      <div className="home-banner">
        <Header />
        <div className="home-mid-text">
          <div className="box1">
            <p className="main-heading-text">GameStart</p>
            {/* <img src={vector} alt="" className="vector" /> */}
          </div>
          <div className="box2">
            <p className="middle-text">
              Explore New Games, Learn About Gaming <br />
              Projects And Get Rewarded For Your <br />
              Contributions.
            </p>
          </div>
          <div className="box3">
            <button className="create-account">Create account</button>
            {/* <button className="search-button">
              <SearchIcon />
              Search for campaign
            </button> */}
            <div className="search-label">
  <div className="search-icon-container">
    <SearchIcon className="search-icon" />
  </div>
  <input
    type="search"
    id="search"
    className="search-input"
    placeholder="Search for campaign"
  />
</div>

          </div>
        </div>
        <div className="home-end-text">
          <p>
            <span>Gaming community</span> platform for gamers to fundraise,{" "}
            <br />
            share, promote and get rewarded
          </p>
        </div>
      </div>
      <div className="home-section2">
        <div className="row">
          <div className="box" style={{ borderRadius: "0 8% 8% 0" }}>
            <img src={games} alt="" />
            <h6>Games</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>
          <div
            className="box"
            style={{ color: "#FFFFFF", backgroundColor: "#020830" }}
          >
            <img src={appgames} alt="" />
            <h6 style={{ fontWeight: "bold" }}>App Games</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>
          <div className="box">
            <img src={socialgaming} alt="" />
            <h6>Social Gaming</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>
          <div className="box" style={{ borderRadius: "8% 0 0 8%" }}>
            <img src={gamingfear} alt="" />
            <h6>Gaming Gear</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="box" style={{ borderRadius: "0 8% 8% 0" }}>
            <img src={esports} alt="" />
            <h6>Esports</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>

          <div className="box">
            <img src={boardGames} alt="" />
            <h6>Board Games</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>
          <div className="box">
            <img src={maps} alt="" />
            <h6>MODS/MAPS/ADDONS</h6>
            <p>
              Lorem Ispum Repudiandae deserunt dicta quia corporis aperiam
              facilis ipsam distinctio. Provident voluptatem.
            </p>
          </div>

        

          <div className="box">
            <img src={otherGaming} alt="" />
            <h6>Other Gaming</h6>
          </div>
          {/* <div className="large-box">
            <p>Other Gaming</p>
          </div> */}
        </div>
      </div>

      <div className="home-section3">
        <div className="row1">
          <p className="box-heading">Why Choose Gamesart?</p>
          <p className="para">
            Our mission is to create rewarding gaming community for <br />
            crowdfunding games, gaming nfts, dlcs, mods, maps, <br />
            gaming gear, esports teams, social media and <br />
            other gaming projects!
          </p>
        </div>
        <div className="row2">
          <div className="box" style={{ borderRadius: "0 8% 8% 0" }}>
            <p className="sub-head">EASY TO USE</p>
            <p className="sub-para">
              Get your gaming fundraising camping running in minutes. Games,
              Mods, Maps, Esports and Social Gaming projects!
            </p>
          </div>
          <div className="box" style={{ borderRadius: "8% 8% 8% 8%" }}>
            <p className="sub-head">GET REWARDED</p>
            <p className="sub-para">
              Get rewarded for your contributions! Get free games, gaming
              subscriptions, event invites or special recognition!
            </p>
          </div>
          <div className="box" style={{ borderRadius: "8% 0% 0% 8%" }}>
            <p className="sub-head">PROMOTE AND SHARE</p>
            <p className="sub-para">
              hare your gaming campaign on your own website and social media!
              Easy copy and paste a few lines of code!
            </p>
          </div>
        </div>
      </div>

      <div className="home-section4">
        <div className="left-box">
          <p>
            Learn more about GameStart and start exploring <br />
            gaming projects!
          </p>

          <button>Learn More</button>
        </div>
        <div className="right-box">
          <img src={section4Img} alt="" />
        </div>
      </div>

      <FooterTop />
      <Footer />
    </>
  );
};

export default Home;

import React from 'react';
import '../styles/Video.css'; // Import a separate CSS file for styling (create this file)
import mobileVideo from "../assets/images/mobileVideo.mov"
const Video = () => {
  return (
    <div className="video-container">
      <video
        className="video-player"
        controls
        width="100%"
        height="100%"
      >
        <source
          src={mobileVideo}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;

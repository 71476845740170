// import React from 'react'

// const Header = () => {
//   return (
//     <div className='header'>
//       <div className="logo">
//         <h6 className="logo-text">GameStart</h6>
//       </div>
//       <div className="navbar"></div>
//       <div className="action-button"></div>
//     </div>
//   )
// }

// export default Header

import React, { useEffect, useState } from "react";
import "../styles/component/Header.css";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/images/logo.png"
import HamburgerMenu from "./Hamburger.jsx";

function Header() {
  const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  return (
    <>
      {isHamburgerMenuOpen && (
        <HamburgerMenu
          setHamburgerMenuOpen={setHamburgerMenuOpen}
          isHamburgerMenuOpen={isHamburgerMenuOpen}
        />
      )}

      <div className="header">
        <div
          className="hamburger"
          onClick={() => setHamburgerMenuOpen((pre) => !pre)}
        >
          <MenuIcon sx={{ fontSize: "30px" }} />
        </div>
        <div className="logo">
          <Link to="/">
            {/* <p className="logo-text">GameStart</p> */}
            <img src={logo} alt=""  className="logo-icon"/>
          </Link>
        </div>
        <div className="menu">
          <ul>
            <Link to="/explore">
              <li>Explore</li>
            </Link>
            <Link to="/campaign">
              <li>New Campaign</li>
            </Link>
            <Link to="/faq">
              <li>FAQ</li>
            </Link>
            <Link to="/video">
              <li>Mobile App Video</li>
            </Link>
          </ul>
        </div>
        <div className="bottomHeaderLast">
          <ul>
          <Link to="/login">
              <li>Login</li>
            </Link>
     
            <Link to="/signup">
              <li>Sign Up</li>
            </Link>
          </ul>
        </div>
      </div>
      {/* {isShowLogin && <Login setIsShowLogin={setIsShowLogin} isShowLogin={isShowLogin}/>} */}
    </>
  );
}

export default Header;

import React, { useState } from 'react'
import Button from '../../components/Button'
import SideBar from '../../components/SideBar'
import Notifications from '../../components/Notifications'
import paymentIcon from '../../assets/icons/paymentIcon.png'
import card1 from '../../assets/icons/card1.svg'
import card2 from '../../assets/icons/card2.svg'
import card3 from '../../assets/icons/card3.svg'
import card4 from '../../assets/icons/card4.svg'
import DashboardHeader from '../../components/DashboardHeader'

export default function ProfileSettings() {
  return (
    <div>
      <div className="relative">
        <DashboardHeader />
      </div>
      <div className="absolute top-0 left-0 lg:left-6">
        <SideBar></SideBar>
      </div>
      <div className="PaymentSetting ml-[20vw] sm:ml-[10vw] mr-[8vw] mb-10">
        <h1 className=" text-black text-[30px] sm:text-[40px] font-medium">Payment Settings</h1>
        <div className="flex gap-2 sm:justify-end">
          <Button text="Discard Changing" bgcolor="white" textcolor="indigo-500" />
          <Button text="Save Changing" bgcolor="indigo-500" textcolor="white" />
        </div>
        <form action="#">
          <h1 className='text-neutral-800 py-2 sm:py-0 text-xl font-semibold leading-loose'>Add a Billing Method</h1>
          <div className="sm:mt-7">
            <div className="flex py-2 sm:py-0 items-center justify-between">
              <label className='block text-gray-900 text-lg font-medium leading-none sm:leading-loose' htmlFor='title'>Routing Number</label>
              <div className="flex items-center justify-center gap-1 sm:gap-2">
                <img src={card4} alt="card" />
                <img src={card3} alt="card" />
                <img src={card2} alt="card" />
                <img src={card1} alt="card" />
              </div>
            </div>
            <div className="relative">
              <input className='rounded-lg pl-20 border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='************' />
              <div className="absolute top-[8px] left-8">
                <img src={paymentIcon} alt="paymenticon" />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full my-2 sm:my-6 gap-4">
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>First Name</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='First Name' />
            </div>
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Last Name</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='Last Name' />
            </div>
          </div>
          <div className="flex items-center justify-center w-full my-2 sm:my-6 gap-4">
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-none sm:leading-loose' htmlFor='title'>Expiration Month</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='MM' />
            </div>
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-none sm:leading-loose' htmlFor='title'>Expiration Year</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='YY' />
            </div>
          </div>
          <div className="">
            <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Security Code</label>
            <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='3 digits' />
          </div>
          <h1 className='text-neutral-800 text-xl font-semibold leading-loose mt-2 sm:mt-9'>Billing Address</h1>
          <div className="flex items-center justify-center w-full my-2 sm:my-6 gap-4">
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Country</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='South Africa' />
            </div>
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-none sm:leading-loose' htmlFor='title'>Address Line 1</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' />
            </div>
          </div>
          <div className="">
            <label className='block py-2 sm:py-0 text-gray-900 text-lg font-medium leading-none sm:leading-loose' htmlFor='title'>Address Line 2 ( Optional)</label>
            <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' />
          </div>
          <div className="flex items-center justify-center w-full my-2 sm:my-6 gap-4">
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>City</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='Capetown' />
            </div>
            <div className="w-1/2">
              <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Postal code</label>
              <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='12345' />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
const CheckboxWithLabel = ({ label = "", description = "" }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="">
        <input name={label} className='focus:outline-none rounded focus:border-none w-6 h-6' type="checkbox" />
      </div>
      <div className="">
        <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
        <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
      </div>
    </div>
  );
};
const RadioWithLabel = ({ label = "", description = "" }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="">
        <input name={label} className='focus:outline-none focus:border-none w-6 h-6' type="radio" />
      </div>
      <div className="">
        <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
        <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
      </div>
    </div>
  );
};
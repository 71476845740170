import React from 'react'
import gameImg from '../assets/images/category-detail-img.png'
export default function BigCard() {
    return (
        <div>
            <div className="w-full lg:w-[23vw] 2xl:w-[18vw] bg-white rounded-[17px] border border-zinc-300 px-4 py-2 pb-5 shadow-2xl" >
                <div className="flex items-center justify-center py-2">
                    <img src={gameImg} alt="gameImg" />
                </div>
                <div className='pt-8'>
                    <p className='text-xl font-normal mb-2'>Alpha</p>
                    <div className="flex h-2 mb-4 overflow-hidden text-xs bg-gray-200 rounded">
                        <div style={{ width: `50%` }} className="flex flex-col justify-center text-center text-white bg-indigo-500 shadow-none whitespace-nowrap"></div>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="leading-[5px]">
                        <p className='text-indigo-500 text-xl font-medium'>$0.00 USD</p>
                        <span className='text-[13px] font-normal '>Pledged</span>
                    </div>
                    <div className="leading-[5px]">
                        <p className='text-indigo-500 text-xl font-medium ml-3'>0%</p>
                        <span className='text-[13px] font-normal'>Funded</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import avatar from '../assets/images/avatar.png'

export default function Avatar() {
    return (
        <div>
            <img src={avatar} alt="avatar" className='rounded-full' />
        </div>
    )
}

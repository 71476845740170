import React, { useState } from 'react'
import Button from '../../components/Button'
import SideBar from '../../components/SideBar'
import Notifications from '../../components/Notifications'
import profile from '../../assets/images/profilepic.svg'
import profileFrame from '../../assets/images/profileFrame.png'
import plus from '../../assets/icons/plus.png'
import DashboardHeader from '../../components/DashboardHeader'

export default function ProfileSettings() {
  return (
    <div>
      <div>
        <DashboardHeader />
      </div>
      <div className="absolute top-0 left-0 lg:left-6">
        <SideBar></SideBar>
      </div>
      <div className="ProfileSettings ml-[20vw] sm:ml-[10vw] mr-[8vw] mb-10">
        <h1 className=" text-black text-[30px] sm:text-[40px] font-medium">Profile Settings</h1>
        <div className="flex gap-2 sm:justify-end">
          <Button text="Delete Acount" bgcolor="white" textcolor="indigo-500" />
          <Button text="Save Changing" bgcolor="indigo-500" textcolor="white" />
        </div>
        <form action="#">
          <div className="flex flex-col sm:flex-row w-full items-center mt-4 sm:mt-0 sm:gap-14">
            <div className=" relative">
              <img className='rounded-full' src={profile} alt="profilepic" />
              <div className="w-[38px] h-[38px] bg-indigo-500 rounded-[11px] flex items-center justify-center absolute bottom-0 right-0">
                <img src={profileFrame} alt="pic" />
              </div>
            </div>
            <div className="my-2 sm:my-6 w-full">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Full Name</label>
              <span className='text-neutral-400 text-base font-light'>Specify your full name</span>
              <div className="flex items-center justify-center w-full mt-1 gap-4">
                <div className="w-1/2">
                  <input className='rounded-lg border border-stone-300 w-full text-neutral-400 text-sm font-light ' type="text" name='title' placeholder='First name' />
                </div>
                <div className="w-1/2">
                  <input className='rounded-lg border border-stone-300 w-full text-neutral-400 text-sm font-light ' type="text" name='title' placeholder='Last name' />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-5">
            <label className='block text-black text-[22px] font-medium' htmlFor='title'>Biography</label>
            <span className='text-neutral-400 text-base font-light'>This will help users get to know you better. Do not worry if you cannot think of anything you can change your bio later on.</span>
            <textarea className='h-[10vh] mt-2 rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='300 max characters' />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-48 sm:gap-y-6 sm:mt-10">
            <div className="relative">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Profile Links</label>
              <span className='text-neutral-400 text-base font-light'>Add social links to your profile. You can have a maximum of 5 links.</span>
              <div className='mt-2 rounded-lg border border-stone-300 w-full text-indigo-500 text-sm font-normal ' type="text"  >Add links</div>
              <div className="absolute right-2 bottom-1">
                <img src={plus} alt="pic" />
              </div>
            </div>
            <div className="relative">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Address</label>
              <span className='text-neutral-400 text-base font-light'>Add your residence area</span>
              <div className='mt-2 rounded-lg border border-stone-300 w-full text-indigo-500 text-sm font-normal ' type="text"  >Add Address</div>
              <div className="absolute right-2 bottom-1 sm:bottom-[50px] lg:bottom-7 2xl:bottom-1">
                <img src={plus} alt="pic" />
              </div>
            </div>
            <div className="relative">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Company</label>
              <span className='text-neutral-400 text-base font-light'>Add Company</span>
              <div className='mt-2 rounded-lg border border-stone-300 w-full text-indigo-500 text-sm font-normal ' type="text"  >Add Company</div>
              <div className="absolute right-2 bottom-1 sm:bottom-7 lg:bottom-1">
                <img src={plus} alt="pic" />
              </div>
            </div>
            <div className="relative">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Phone Number</label>
              <span className='text-neutral-400 text-base font-light'>Add your personal phone number</span>
              <div className='mt-2 rounded-lg border border-stone-300 w-full text-indigo-500 text-sm font-normal ' type="text"  >Add phone number</div>
              <div className="absolute right-2 bottom-1">
                <img src={plus} alt="pic" />
              </div>
            </div>
            <div className="">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Security</label>
              <span className='text-neutral-400 text-base font-light'>Add security by scanning the QR code</span>
              <div className='mt-2 rounded-lg border bg-indigo-500 bg-opacity-10 border-stone-300 w-full text-indigo-500 text-sm font-normal ' type="text"  >Enable two-factor authorization</div>
            </div>
            <div className="">
              <label className='block text-black text-[22px] font-medium' htmlFor='title'>Edit acount setting</label>
              <span className='text-neutral-400 text-base font-light'>Change your account credential</span>
              <div className='mt-2 rounded-lg border bg-indigo-500 bg-opacity-10 border-stone-300 w-full text-indigo-500 text-sm font-normal ' type="text"  >Edit</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
const CheckboxWithLabel = ({ label = "", description = "" }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="">
        <input name={label} className='focus:outline-none rounded focus:border-none w-6 h-6' type="checkbox" />
      </div>
      <div className="">
        <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
        <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
      </div>
    </div>
  );
};
const RadioWithLabel = ({ label = "", description = "" }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="">
        <input name={label} className='focus:outline-none focus:border-none w-6 h-6' type="radio" />
      </div>
      <div className="">
        <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
        <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
      </div>
    </div>
  );
};
import React from "react";
import Header from "../components/Header";
import FooterTop from "../components/FooterTop";
import Footer from "../components/Footer";
import vector from "../assets/icons/Vector.png";
import "../styles/Faq.css"
const Faq = () => {
  return (
    <>
      <div className="faq-container">
        <Header />

        <div className="box1">
          <sapn className="main-heading-text">
            {" "}
            <span>GameStart</span>- Frequently Asked Questions
          </sapn>
          {/* <img src={vector} alt="" className="vector" /> */}
        </div>

        <div className="box">
          <p className="box-heading">What is GameStart?</p>
          <p className="box-para" style={{marginBottom: "23px"}}>
            GameStart is a crowdfunding platform committed to help gamers bring
            their gaming dreams and ideas to life and share their success
            stories with the gaming community by connecting gamers with backers,
            family, friends, sponsors and gaming fans around the world.
          </p>
          <br />
          <br />
          <p className="box-para"style={{marginBottom: "23px"}}>
            GameStart is not an investing company, you can think of GameStart as
            of Kickstarter® dedicated to gaming. ("Kickstarter" is a registered
            trademark of Kickstarter, PBC.){" "}
          </p>
          <p className="box-para">
            Gaming creators and gaming teams keep 100% ownership of their gaming
            projects and teams. GameStart cannot be used for financial gain,
            solicitation for loans or other types of equity.
          </p>
        </div>
        <div className="box">
          <p className="box-heading">
            What Rewards Are Offered By Campaign Creators?
          </p>
          <p className="box-para"style={{marginBottom: "23px"}}>
            Gaming content creators and gaming teams might offer rewards for
            contributions to their gaming campaigns.
          </p>
          <p className="box-para"style={{marginBottom: "23px"}}>
            Rewards could range from free copies of the game, t- shirts, gaming
            memberships, to gaming events and once in a lifetime gaming
            experiences. Gaming mods and maps are mostly thank you recognition
            campaigns.
          </p>
        </div>
        <div className="box">
          <p className="box-heading">
            What about GameStart contributions and fees?
          </p>
          <p className="box-para">
            GameStart crowdfunding platform is a marketplace that allows gamers,
            developers, contributors, backers, sponsors, family, friends and
            fans support all types of gaming campaigns and the funds go directly
            to gaming creators and gaming teams who represent their gaming
            campaigns. <br />
            GameStart campaigns can be set to run in either 'Keep it All' or
            'All or Nothing' raise modes.
            <br />
            Keep it All will charge contributor’s credit cards and distribute
            the funds as contributions are received directly to campaign
            creators.
            <br />
            All or Nothing will charge contributor’s credit cards and distribute
            the funds directly to gaming creators once the campaign end time is
            reached and funding goal has been met. Otherwise contributions will
            not be processed.
            <br />
            All contributions made on GameStart processed with the following
            fees:
            <br />
            ✓ 97% to the Gaming Campaign Creator – to make their Gaming Projects
            Become a Success
            <br />
            ✓ Around 3% Stripe Processing Fees – to process industry standard
            secure credit card transactions
            <br />
            ✓ 0% GameStart platform Fee – we welcome your donations to help us
            with future development of the platform
            <br />
            If there are any changes to fees for future campaigns we will let
            you know ahead of the time!
            <br />
          </p>
        </div>
        <div className="box">
          <p className="box-heading">Guidelines for Gaming Campaign Creators</p>
          <ul className="box-ul">
            <li className="box-li">Set the Goals for Your Gaming Campaign</li>
            <li className="box-li">
              Split Campaigns into Phases for Larger Gaming Projects
            </li>
            <li className="box-li">
              Describe Your Gaming Project or Gaming Team
            </li>
            <li className="box-li">Show Your Prior Experience if any</li>
            <li className="box-li">Set Clear Expectations and Time Frames</li>
            <li className="box-li">
              Describe Any Rewards You can offer to your Contributors
            </li>
          </ul>
        </div>
        <div className="box">
          <p className="box-heading">
            Guidelines for Backers, Contributors, Family, Friends, Sponsors and
            Fans
          </p>
          <ul className="box-ul">
            <li className="box-li">
              Review Gaming Project or Gaming Team in detail
            </li>
            <li className="box-li">Ask Questions if You have any</li>
            <li className="box-li">Decide if You are ready to Contribute</li>
            <li className="box-li">Explore offered Rewards or Recognition</li>
            <li className="box-li">Make Your Contribution</li>
          </ul>
        </div>
        <div className="box">
          <p className="box-heading">Transactions and Security</p>
          <p className="box-para">
            Any contributions by backers, friends, family, sponsors and fans are
            securely processed and stored by STRIPE, the same company trusted by
            Amazon. <br />
            You will need a valid credit card for all contributions. As U.S.
            regulation develops we plan to allow blockchain transactions. <br />
            GameStart is DBA of HappyGamer, Inc. All your credit card
            contributions will show 'GAMESTART' for the charge. <br />
          </p>
        </div>
      </div>
      <FooterTop />
      <Footer />
    </>
  );
};

export default Faq;

import React from 'react'

export default function Button({ text, bgcolor, textcolor }) {
    return (
        <div>
            <div className={`px-4 py-2 bg-${bgcolor} rounded-[9px] text-${textcolor} text-xs font-normal flex items-center justify-center border  border-indigo-500`} >
                {text}
            </div>
        </div>
    )
}

import React from 'react'
import googleIcon from "../../assets/icons/googleIcon.png"
import github from "../../assets/icons/github.png"
import "../../styles/Login.css"
import loginBG from "../../assets/images/loginBG.png"
import { Link } from 'react-router-dom'
const Login = () => {
  return (
    <>
      <div className="login-container">
        <div className="left-box">
          <img src={loginBG} alt="" className="left-img" />
        </div>
        <div className="right-box">
          <div className="page-heading">
            <p className="style-p">
              Welcome back to the Gamestart
            </p>
          </div>
          <div className="social-button-box">
            <button className="btn-social"><img src={googleIcon} alt="" className="btn-img" /> Login with Google</button>
            <button className="btn-social"><img src={github} alt="" className="btn-img" />Login with Github</button>
          </div>
          <div className="login-form">
            <div className="input-row">
              <label htmlFor="email" className="input-label">Email</label>
              <input type="email" id='email' placeholder='johndoe@email.com' className="input-field" />
            </div>
            <div className="input-row">
              <label htmlFor="pass" className="input-label">Password</label>
              <input type="password" id='pass' placeholder="12345678" className="input-field" />
            </div>

            <div className="checkbox-row">
              <div className="check-left">
                <input type="checkbox" className="input-check" />
                <label htmlFor="" className="input-check-label">Remember me</label>
              </div>
              <button className="login"> <Link to="/home-dashboard"> Login</Link></button>

            </div>
          </div>
          <p className="page-end-line">No Account yet?  <Link to="/signup"><span className="inner-style">SIGN UP</span></Link></p>
        </div>
      </div>
    </>
  )
}

export default Login    
import React from 'react'
import googleIcon from "../../assets/icons/googleIcon.png"
import github from "../../assets/icons/github.png"
import "../../styles/signUp.css"
import loginBG from "../../assets/images/loginBG.png"
import { Link } from 'react-router-dom'
const SignUp = () => {
  return (
    <>
    <div className="signup-container">
      <div className="left-box">
        <img src={loginBG} alt="" className="left-img" />
      </div>
      <div className="right-box">
        <div className="page-heading">
          <p className="style-p">
          Join & Connect the Fastest Growing Gaming Community Platform
          </p>
        </div>
        <div className="social-button-box">
          <button className="btn-social"><img src={googleIcon} alt="" className="btn-img" /> Sign up with Google</button>
          <button className="btn-social"><img src={github} alt="" className="btn-img" />Sign up with Github</button>
        </div>

        <div className="signup-form">
          <div className="input-row">
            <label htmlFor="userName" className="input-label">username</label>
            <input type="text"  id='userName' placeholder='johndadev' className="input-field" />
            </div>
            <div className="input-row">
            <label htmlFor="email" className="input-label">Email</label>
            <input type="email" id='email' placeholder='johndoe@email.com' className="input-field" />
            </div>
            <div className="input-row">
            <label htmlFor="pass" className="input-label">Password</label>
            <input type="password" id='pass' placeholder="12345678" className="input-field" />
            </div>

            <div className="checkbox-row">
              <input type="checkbox" className="input-check" />
              <label htmlFor="" className="input-check-label">I accept the terms & Condition</label>
            </div>
            <button className="signup-contributor">Sign up as contributors</button>
            <button className="signup-bakers">Sign up as bakers</button>
        </div>

<p className="page-end-line">Own an Account? <Link to="/login"><span className="inner-style">JUMP RIGHT IN</span></Link> </p>


      </div>
    </div>
    </>
  )
}

export default SignUp

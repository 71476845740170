import React from 'react'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
export default function DropDown(props) {
    const items = [
        {
            label: <a href="https://www.antgroup.com">1st menu item</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com">2nd menu item</a>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ];
    return (
        <div className='border border-zinc-400 rounded-lg px-2'>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={['click']}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space className='text-[10px] sm:text-xs font-light py-1'>
                        {props.text}
                        <DownOutlined className='text-xs pl-2' />
                    </Space>
                </a>
            </Dropdown>
        </div>
    )
}
import React from 'react'
import DashboardHeader from '../../components/DashboardHeader'
import SideBar from '../../components/SideBar'
import FooterTop from '../../components/FooterTop'
import Footer from '../../components/Footer'


export default function TermsOfService() {
    const heading = 'text-neutral-950 text-[28px] lg:text-[40px] font-medium my-4 sm:my-9'
    const subHeading = 'text-neutral-950 text-lg sm:text-2xl font-medium my-2 sm:my-4'
    const paragraph = 'text-black text-[15px] sm:text-[22px] font-light my-2 sm:my-4'
    return (
        <div>
            <div className="">
                <DashboardHeader />
            </div>
            <div className="absolute top-0 left-0 lg:left-6">
                <SideBar></SideBar>
            </div>
            <div className="TermsOfService ml-20 sm:mx-20 lg:mx-32 mb-20">
                <h1 className={heading}>Gamestart Terms of Service</h1>
                {/* <span className="text-neutral-950 text-sm font-light">Last updated: [Date]</span> */}
                <p className={paragraph}>Welcome to Gamestart! These Terms of Service ("Terms") govern your use of the Gamestart website (the "Site") and any related services provided by Gamestart (collectively referred to as the "Service"). By accessing or using the Service, you agree to comply with and be bound by these Terms.</p>
                <h2 className={subHeading}>Acceptance of Terms</h2>
                <p className={paragraph}>By using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.</p>
                <h2 className={subHeading}>Changes to Terms</h2>
                <p className={paragraph}>Gamestart reserves the right to update or modify these Terms at any time without prior notice. It is your responsibility to review these Terms periodically. Your continued use of the Service after any changes constitutes acceptance of those changes.</p>
                <h2 className={subHeading}>User Accounts</h2>
                <p className={paragraph}>To use certain features of the Service, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                <h2 className={subHeading}>User Responsibilities</h2>
                <p className={paragraph}>You are solely responsible for your use of the Service and for any content you post. You agree not to use the Service for any unlawful purpose or in any way that could damage the reputation of Gamestart or impair the proper functioning of the Service.</p>
                <h2 className={subHeading}>Intellectual Property</h2>
                <p className={paragraph}>All content and materials available on the Service, including but not limited to text, graphics, logos, images, and software, are the property of Gamestart or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
                <h2 className={subHeading}>Termination</h2>
                <p className={paragraph}>Gamestart reserves the right to terminate or suspend your account and access to the Service for any reason, without prior notice.</p>
                <h2 className={subHeading}>Limitation of Liability</h2>
                <p className={paragraph}>Gamestart shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.</p>
                <h2 className={subHeading}>Governing Law</h2>
                <p className={paragraph}>These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction].</p>
                <h2 className={subHeading}>Contact Information</h2>
                <p className={paragraph}>If you have any questions about these Terms, please contact us at [your contact email].</p>
            </div>
            <FooterTop/>
            <Footer/>
        </div>
    )
}

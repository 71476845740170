import React from 'react'
import DashboardHeader from '../../components/DashboardHeader'
import SideBar from '../../components/SideBar'
import FooterTop from '../../components/FooterTop'
import Footer from '../../components/Footer'


export default function PrivacyPolicy() {
    const heading = 'text-neutral-950 text-[28px] lg:text-[40px] font-medium my-4 sm:my-9'
    const subHeading = 'text-neutral-950 text-lg sm:text-2xl font-medium my-2 sm:my-4'
    const paragraph = 'text-black text-[15px] sm:text-[22px] font-light my-2 sm:my-4'
    return (
        <div>
            <div className="">
                <DashboardHeader />
            </div>
            <div className="absolute top-0 left-0 lg:left-6">
                <SideBar></SideBar>
            </div>
            <div className="PrivacyPolicy ml-20 sm:mx-20 lg:mx-32 mb-20">
                <h1 className={heading}>The Privacy Policy</h1>
                <p className={paragraph}>Welcome to Gamestart! This Privacy Policy outlines how Gamestart ("we," "us," or "our") collects, uses, discloses, and protects your personal information when you use our website, located at [website URL] (the "Site"). By accessing or using the Site, you agree to the terms of this Privacy Policy.</p>
                <h1 className={heading}>Information We Collect</h1>
                <h2 className={subHeading}>Personal Information:</h2>
                <p className={paragraph}>We may collect personal information such as your name, email address, and other contact details when you register for an account, subscribe to our newsletter, or participate in contests and promotions.</p>
                <h2 className={subHeading}>Usage Data:</h2>
                <p className={paragraph}>We automatically collect information about your usage of our website, including your IP address, device information, and browsing patterns. This information helps us improve the user experience and analyze trends.</p>
                <h2 className={subHeading}>Cookies:</h2>
                <p className={paragraph}>We may collect personal information such as your name, email address, and other contact details when you register for an account, subscribe to our newsletter, or participate in contests and promotions.</p>
                <h2 className={subHeading}>Usage Data:</h2>
                <p className={paragraph}>We use cookies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</p>
                <h1 className={heading}>How We Use Your Information</h1>
                <h2 className={subHeading}>Providing Services:</h2>
                <p className={paragraph}>We use your personal information to provide and personalize our services, respond to your inquiries, and deliver relevant content.</p>
                <h2 className={subHeading}>Analytics:</h2>
                <p className={paragraph}>We analyze usage data to improve our website, optimize content, and understand user preferences.</p>
                <h2 className={subHeading}>Communication:</h2>
                <p className={paragraph}>We may use your contact information to send you updates, newsletters, and promotional materials. You can opt-out of these communications at any time.</p>
                <h1 className={heading}>Sharing Your Information</h1>
                <h2 className={subHeading}>Third-Party Service Providers:</h2>
                <p className={paragraph}>We may share your information with third-party service providers who assist us in operating our website and delivering services</p>
                <h2 className={subHeading}>Legal Compliance:</h2>
                <p className={paragraph}>We may disclose your information to comply with legal obligations or respond to lawful requests from public authorities.</p>
                <h1 className={heading}>Your Choices</h1>
                <h2 className={subHeading}>Access and Correction:</h2>
                <p className={paragraph}>You can access and update your personal information through your account settings. If you need assistance, please contact us.</p>
                <h2 className={subHeading}>Opt-Out:</h2>
                <p className={paragraph}>You can opt-out of receiving promotional communications by following the unsubscribe instructions provided in the emails or contacting us directly.</p>
                <h1 className={heading}>Security</h1>
                <p className={paragraph}>We take reasonable measures to protect your information from unauthorized access or disclosure. However, no method of transmission over the internet is completely secure.</p>
                <h1 className={heading}>Changes to this Privacy Policy</h1>
                <p className={paragraph}>We reserve the right to update this Privacy Policy at any time. The most recent version will be posted on our website, and your continued use of the site constitutes acceptance of the updated terms.</p>
                <h1 className={heading}>Contact Us</h1>
                <p className={paragraph}>If you have any questions or concerns about our Privacy Policy, please contact us at [Your Contact Information].</p>
            </div>
            <FooterTop/>
            <Footer/>
        </div>
    )
}

import React, { useState } from 'react';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import logout from '../assets/icons/logout.png'
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo.png"

export default function SideBar() {
    const [showText, setShowText] = useState(false);

    const toggleTextVisibility = () => {
        setShowText(!showText);
    };

    return (
        <div>
            <div className="flex min-h-[95vh] sm:my-4 fixed top-0 z-10">
                <div className={`${showText ? 'sm:w-64 sm:min-w-0' : 'w-16'} ${showText ? 'min-w-[100vw]' : 'w-16'}   bg-white rounded-3xl space-y-5 sm:space-y-7 relative overflow-hidden transition-all duration-100 shadow-2xl`}>
                    {showText ? (
                        <div className="text-black text-[25px] font-normal text-center pt-8"><Link to="/home-dashboard"> <img className='w-3/5 ml-16' src={logo} alt="GS" /> </Link></div>
                    ) : (
                        <div className="text-black text-[25px] font-normal text-center pt-8">G.. </div>
                    )}

                    <div className="flex items-center justify-between px-5">
                        <Link to="/mydashboard">
                            <GridViewOutlinedIcon className="text-indigo-500 mr-3" />

                            {showText && (
                                <span className="sm:mr-10 mr-28 text-black font-light text-lg transition-opacity opacity-100">
                                    My Dashboard
                                </span>
                            )}
                        </Link>
                    </div>




                    <div className="flex items-center justify-between px-5">
                        <Link to="/admindashboard">
                            <AccountCircleRoundedIcon className="text-black hover:bg-indigo-500 mr-3" />

                            {showText && (
                                <span className="sm:mr-10 mr-28 text-black font-[275] text-lg transition-opacity opacity-100">
                                    Administration
                                </span>
                            )}
                        </Link>
                    </div>




                    <div className="flex items-center justify-between px-5">
                        <Link to="/messages">
                            <MailOutlineIcon className="text-black mr-3" />

                            {showText && (
                                <span className="sm:mr-12 mr-[125px] font-[275] text-black text-lg transition-opacity opacity-100">
                                    My Messages
                                </span>
                            )}
                        </Link>
                    </div>


                    <div className="flex items-center justify-between px-5">
                        <Link to="/dashboardcompaign">

                            <PermIdentityIcon className="text-black mr-3" />
                            {showText && (
                                <span className="sm:mr-10 mr-28 text-black font-[275] text-lg transition-opacity opacity-100">
                                    Add Campaign
                                </span>
                            )}
                        </Link>

                    </div>



                    <div className="flex items-center justify-between px-5">
                        <Link to="/profilesettings">
                            <SettingsOutlinedIcon className="text-black mr-3" />

                            {showText && (
                                <span className="sm:mr-10 mr-28 text-black font-[275] text-lg transition-opacity opacity-100">
                                    Profile Settings
                                </span>
                            )}
                        </Link>
                    </div>

                    <div className="flex items-center justify-between px-5">
                        <Link to="/paymentsetting">
                            <PaymentsOutlinedIcon className="text-black mr-3" />
                            {showText && (
                                <span className="sm:mr-8 mr-[110px] text-black font-[275] transition-opacity opacity-100">
                                    Payment Settings
                                </span>
                            )}
                        </Link>
                    </div>


                    <div className="flex items-center justify-between px-5 absolute bottom-12">
                        {showText ? (
                            <span className="pl-28 sm:pl-20 pb-5 text-indigo-500 text-xl font-semibold transition-opacity opacity-100">
                                Logout
                            </span>
                        ) :
                            <img src={logout} alt="logout" />
                        }
                    </div>
                </div>

                <div className="mt-24 ml-2 sm:ml-4 relative" onClick={toggleTextVisibility}>
                    {showText ? (
                        <KeyboardArrowLeftOutlinedIcon className="text-white w-[26px] h-[26px] left-0 top-0 absolute origin-top-left rotate-180 bg-slate-900 rounded-full" />
                    ) : (
                        <ChevronRightOutlinedIcon className="text-white w-[26px] h-[26px] left-0 top-0 absolute origin-top-left rotate-180 bg-slate-900 rounded-full" />
                    )}
                </div>
            </div>
        </div >
    );
}

'use client';
import { Checkbox, Table } from 'flowbite-react';
import React from 'react'
import DropDown from './DropDown';
import heart from '../assets/icons/heart.svg'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import line from '../assets/icons/Vector 51.png'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

export default function AdminCompaign() {
    const items = [
        {
            label: <a href="https://www.antgroup.com">1st menu item</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com">2nd menu item</a>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ];
    return (
        <div>
            <div className="flex items-center sm:items-start lg:items-center flex-col lg:flex-row justify-between sm:pb-4 ">
                <div className="flex items-center sm:space-x-2 pl-6 sm:pl-0 pb-2 sm:pb-0">
                    <DropDown text="Actions"></DropDown>
                    <DropDown text="Category"></DropDown>
                    <DropDown text="Status"></DropDown>
                    <DropDown text="Type"></DropDown>
                </div>
                <div className="rounded-[9px] flex space-x-4 border border-zinc-400 ml-6 sm:ml-0">
                    <input type="text" className='text-xs font-light outline-none border-none ml-2' placeholder='Search by...' />
                    <img src={line} alt="line" />
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className='text-[10px] sm:text-xs lg:pt-2 font-light '>
                                Campaign name
                                <DownOutlined className='text-xs pr-2 lg:pl-2 ' />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className="flex items-center justify-between py-2">
                <div className="text-base sm:text-[22px] font-medium">All Compaigns</div>
                <div className="flex items-center sm:space-x-4">
                    <p className='text-[11px] font-light'>Show result</p>
                    <div className='border border-indigo-500 rounded-3xl px-3'>
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space className='text-xs font-light '>
                                    20
                                    <DownOutlined className='text-xs pl-10' />
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto">
                <Table hoverable className='shadow-2xl border'>
                    <Table.Head >
                        <Table.HeadCell className="p-4 bg-indigo-500 text-white text-center text-sm font-normal">
                            <Checkbox />
                        </Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Feature </Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>ID</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Name</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Creator</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Created</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Ends/Ended</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Action</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Status</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>Order by display priority</Table.HeadCell>
                        <Table.HeadCell className='bg-indigo-500 text-white text-center text-sm font-normal'>
                            <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y text-black text-xs font-light ">
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white border-b">
                            <Table.Cell className="p-4">
                                <Checkbox />
                            </Table.Cell>
                            <Table.Cell className='ml-6'>
                                <img src={heart} alt="heart" />
                            </Table.Cell>
                            <Table.Cell>9</Table.Cell>
                            <Table.Cell>Test Campaign 9</Table.Cell>
                            <Table.Cell>GameStart Admin</Table.Cell>
                            <Table.Cell>2022-03-23</Table.Cell>
                            <Table.Cell>497 days ago</Table.Cell>
                            <Table.Cell>
                                <DropDown text="Select"></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <DropDown text="Being Edited" ></DropDown>
                            </Table.Cell>
                            <Table.Cell>
                                <button className='bg-indigo-500 bg-opacity-10 rounded-[9px] px-14 py-3'>Transactions</button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-between">
                <div className="py-6 space-x-2 space-y-1 lg:space-y-0">
                    <button className="text-white text-[10px] font-light px-2.5 py-2 ml-2 sm:ml-0 bg-indigo-500 rounded-[9px]">
                        Download CSV
                    </button>
                    <button className="text-white text-[10px] font-light px-2.5 py-2 bg-indigo-500 rounded-[9px]">
                        Export all campaign to CSV (basic)
                    </button>
                    <button className="text-white text-[10px] font-light px-2.5 py-2 bg-indigo-500 rounded-[9px]">
                        Export all campaign to CSV (Advanced)
                    </button>
                    <button className="text-white text-[10px] font-light px-2.5 py-2 bg-indigo-500 rounded-[9px]">
                        Export transections for selected campaigns
                    </button>
                </div>
                <div className="flex items-center space-x-2">
                    <p className='text-sm font-normal'>page 01:</p>
                    <KeyboardArrowLeftOutlinedIcon className="text-white w-[26px] h-[26px] rounded-full bg-indigo-500" />
                    <ChevronRightOutlinedIcon className="text-white w-[26px] h-[26px] rounded-full bg-indigo-500" />
                </div>
            </div>
        </div>
    )
}






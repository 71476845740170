import React from 'react'
import myimg from '../assets/images/Rectangle 204.png'
import loc from '../assets/icons/location.svg'
import fb from '../assets/icons/fb.png'
import yt from '../assets/icons/yt.png'
import twt from '../assets/icons/twiter.png'
import star from '../assets/icons/Vector.png'
import line from '../assets/icons/Vector 51.png'

export default function mydash() {
    return (
        <div>
            <div className="w-[85vw] sm:w-[43vw] lg:w-[32vw] xl:w-[25vw] flex flex-col min-h-[95vh] sm:my-4 shadow-2xl px-9 py-10 rounded-2xl">
                <div className="flex items-center justify-center py-2">
                    <img className=' rounded-[90px]' src={myimg} alt="myimg" />
                </div>
                <div className="text-black text-center text-[25px] font-medium">Ryan Timoshenko</div>
                <div className="flex space-x-3 items-center justify-center">
                    <img src={loc} alt="loc" />
                    <p className='text-stone-300 text-lg font-light'>Helsinki, Finland</p>
                </div>
                <div className="my-4 py-4 px-10 flex items-center justify-center bg-indigo-500 rounded-2xl text-white text-lg font-normal " >
                    Contact Creator
                </div>
                <div className="flex flex-col py-2">
                    <h1 className='text-black text-lg font-light '>About</h1>
                    <p className='text-neutral-600 text-[13px] font-light '>Voluptatibus sit repellat vel eligendi quibusdam et quam itaque  sectetur. Quisquam quam hic sunt ipsam  iunt aut minus eaque. Autem eos.
                        laboriosam. Sequi ratione pariat non pariatur dignissimos. Cumque itaque consequatur alias quasi. Labore tiae fugiat dolores rerum explicabo rerum eum provident.</p>
                </div>
                <div className="flex flex-col py-2">
                    <h1 className='text-black text-lg font-light '>On the Web</h1>
                    <div className="flex flex-col space-y-2">
                        <div className="flex items-center justify-center px-24 py-2 rounded-[9px] border border-stone-300" >
                            <img className='h-3 px-2' src={fb} alt="icon" />Facebook
                        </div>
                        <div className="flex items-center justify-center px-24 py-2 rounded-[9px] border border-stone-300" >
                            <img className='h-3 px-2' src={yt} alt="icon" />Youtube
                        </div>
                        <div className="flex items-center justify-center px-24 py-2 rounded-[9px] border border-stone-300" >
                            <img className='h-3 px-2' src={twt} alt="icon" />Twitter
                        </div>
                    </div>
                </div>
                <div className="pt-40">
                    <div className="text-neutral-400 text-xs font-normal flex items center justify-center">
                        MEMBER SINCE: AUGUST 25, 2023
                    </div>
                    <div className="py-2 flex items center justify-center space-x-2">
                        <div className="text-neutral-400 text-xs font-light font-['Poppins']">Report </div>
                        <img src={line} alt="line" />
                        <div className="text-neutral-400 text-xs font-light font-['Poppins']">Block</div>
                    </div>
                </div>
                <div className="absolute top-8 right-8">
                    <img src={star} alt="star" />
                </div>
                <div className="absolute bottom-8 left-8">
                    <img src={star} alt="star" />
                </div>
            </div>
        </div>
    )
}

// Notifications.js
import React, { useState } from 'react';

const Notifications = ({ closeNotification }) => {

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-indigo-500 bg-opacity-10 backdrop-blur-[7px] z-50">
            <div className="bg-white p-8 rounded-lg absolute sm:right-16 top-20 sm:top-32 bottom-1 sm:bottom-9 sm:w-[41vw]  overflow-auto space-y-2">
                <div className="text-black text-[22px] sm:text-[40px] font-medium">Notification</div>
                <div className="flex justify-end">
                    <button className="" onClick={closeNotification}>
                        Close
                    </button>
                </div>
                <UnSeenNotifications title="New notification" text="Minima pariatur veniam provident distinctio laborum sint praesentium quia. Neque eligendi est...." />
                <UnSeenNotifications title="New notification" text="Minima pariatur veniam provident distinctio laborum sint praesentium quia. Neque eligendi est...." />
                <UnSeenNotifications title="New notification" text="Minima pariatur veniam provident distinctio laborum sint praesentium quia. Neque eligendi est...." />
                <SeenNotifications title="yesterday" text="Minima pariatur veniam provident distinctio laborum sint praesentium quia. Neque eligendi est.... " />
                <SeenNotifications title="yesterday" text="Minima pariatur veniam provident distinctio laborum sint praesentium quia. Neque eligendi est.... " />
                <SeenNotifications title="yesterday" text="Minima pariatur veniam provident distinctio laborum sint praesentium quia. Neque eligendi est.... " />
            </div>
        </div>
    );
};

const UnSeenNotifications = ({ title, text }) => {
    return (
        <>
            <div className="px-5 py-2 bg-indigo-500 bg-opacity-10 rounded-[9px] flex items-center justify-between">
                <div className="w-3/4">
                    <h1 className="text-indigo-500 text-[15px] font-medium  leading-none">{title}</h1>
                    <p className=" text-zinc-800 text-sm font-light leading-none py-2">{text}</p>
                </div>
                <div className="">
                    <div className="w-[23px] h-[23px] bg-indigo-500 rounded-full border border-white flex items-center justify-center text-white text-xs" >1</div>
                </div>
            </div>
        </>
    )
}
const SeenNotifications = ({ title, text }) => {
    return (
        <div className="px-5 py-2 bg-indigo-500 bg-opacity-10 rounded-[9px]">
            <h1 className="text-zinc-600 text-[15px] font-medium  leading-none">{title}</h1>
            <p className=" text-zinc-800 text-sm font-light leading-none py-2">{text}</p>
        </div>
    )
}

export default Notifications;
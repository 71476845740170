import React, { useState } from 'react'
import SideBar from '../../components/SideBar'
import send from '../../assets/icons/Send 3.png'
import search from '../../assets/icons/search-zoom-in.png'
import dp from '../../assets/images/dp.png'
import dp2 from '../../assets/images/dp2.png'
import smiley from '../../assets/images/Smiley.png'
import paperclip from '../../assets/images/Paperclip.png'
import { useMediaQuery } from 'react-responsive';
import DashboardHeader from '../../components/DashboardHeader'


const Messages = () => {
  const isMobile = useMediaQuery({ maxWidth: 639 }); // Define the maximum width for mobile
  const [isChatOpen, setIsChatOpen] = useState(false);

  const openChat = () => {
    setIsChatOpen(true);
  };

  const closeChat = () => {
    setIsChatOpen(false);
  };

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim() === '') {
      return;
    }

    const updatedMessages = [...messages, { text: newMessage, sender: 'user' }];
    setMessages(updatedMessages);
    setNewMessage('');
  };

  return (
    <div>
      <div className="relative">
        <DashboardHeader />
      </div>
      <div className="absolute top-0 left-0 lg:left-6">
        <SideBar></SideBar>
      </div>
      <div className="pl-[70px] sm:pl-20 lg:pl-32">
        <div className="text-black text-3xl sm:text-[40px] font-medium pb-4">Message Center</div>
        <div className="flex px-2 py-2 rounded-2xl border border-zinc-300 mb-20 sm:mr-10">
          <div className={`left border-r w-full sm:w-[50%] lg:w-[43%] xl:w-[30%] max-w-[400px] lg:pl-4  ${isChatOpen && isMobile ? 'hidden' : ''}`}>
            <div className="relative my-2 sm:my-4">
              <input className='focus:outline-none text-zinc-600 text-[10px] font-light tracking-tight rounded-[9px] border border-zinc-400 w-72 lg:w-[330px] pl-10' type="text" placeholder='Search or start a new chat' />
              <img src={search} className='absolute top-3 left-4' alt="search" />
            </div>
            <div className="contacts">
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
              <div onClick={openChat} className="flex items-center justify-between py-2 hover:shadow-lg hover:border hover:rounded-2xl">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp} alt="dp" />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-[15px] font-normal tracking-tight">GAPD DesPro</h1>
                  <p className="text-zinc-600 text-[13px] tracking-tight">asperiores laboriosam volup...</p>
                </div>
                <div className="space-y-1 pr-2">
                  <div className="text-neutral-400 text-xs font-normal tracking-tight">17:36</div>
                  <div className="w-[21.41px] h-[21.41px] bg-indigo-500 rounded-full border border-white text-white text-[10px] font-light flex items-center justify-center" >5</div>
                </div>
              </div>
            </div>
          </div>
          {isChatOpen &&
            <div className="px-1 sm:px-4 py-4 relative min-h-[70vh] w-full">
              <button className="text-indigo-500 sm:hidden" onClick={closeChat}>
                Back
              </button>
              <div className="flex space-x-2">
                <div className="w-[60.15px] h-[60.15px] rounded-full border border-white">
                  <img src={dp2} alt="dp" />
                </div>
                <div className="">
                  <h1 className='text-indigo-500 text-base font-semibold tracking-tight'>Ejinne Seun</h1>
                  <p className='text-neutral-400 text-sm font-light tracking-tight'>Online</p>
                </div>
              </div>

              {/* Chat container */}
              <div className="">
                <div className="flex flex-col mt-4 space-y-2 ">
                  {messages.map((message, index) => (
                    <div key={index} className={message.sender === 'user' ? 'text-right' : 'text-left'}>
                      <p className={`bg-indigo-500 rounded-[14px] px-3 sm:px-6 py-2 sm:py-4 inline-block text-white text-sm font-light leading-3`}>
                        {message.text}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex items-center w-full absolute bottom-0 left-0 sm:space-x-2 lg:space-x-4 px-1 sm:px-2">
                <div className="w-[32.62px] h-[32.62px]">
                  <img src={smiley} alt="smiley" />
                </div>
                <div className="w-[32.62px] h-[32.62px]">
                  <img src={paperclip} alt="paperclip" />
                </div>
                <input value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)} className='w-full border-none text-black bg-indigo-500 bg-opacity-10 rounded-[10.19px] backdrop-blur-[20.39px] text-xs lg:text-sm font-light tracking-tight lg:px-6 lg:py-6' type="text" placeholder='Type a message or send a voice note' />
                <div className="w-[32.62px] h-[32.62px] absolute right-1 top-1 lg:top-5 lg:right-4">
                  <img src={send} onClick={handleSendMessage} alt="send" />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Messages

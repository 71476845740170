import React from 'react'
import "../styles/component/Footer.css"
const Footer = () => {
  return (
   <div className="footer">
    <div className="row1">
        <p>GameStart </p>
    </div>
    <div className="row2">
        <div className="box">
        



            <ul>
                <li>Home</li>
                <li>Explore</li>
                <li>New Campaign</li>
                <li>Terms of Services</li>
                <li>Privacy Policy</li>
            </ul>
        </div>
        <div className="box">
        <ul>
              <li>Need Help?</li>
                <li>About Gamestart</li>
                <li>Contact Us</li>
                <li>FAQs</li>
                <li>Cookies Policy</li>
                <li>DMCA Policy</li>

            </ul>
        </div>
        <div className="box">
        <ul>
    
                <li>All Catagories</li>
                <li>GAMES</li>
                <li>APP GAMES</li>
                <li>SOCIAL GAMING</li>
                <li>GAMING GEAR</li>
                <li>OTHER GAMING</li>
            </ul>
        </div>
        
        <div className="box">
        <ul>
               <li>ESPORTS</li>
                <li>MODS/MAPS/ADDONS</li>
                <li>OTHER GAMING</li>
               
            </ul>
        </div>

        {/* <div className="last-box">
            <p>© Copyright 2023. All rights reserved.</p>
        </div> */}
    </div>
   </div>
  )
}

export default Footer

import React from 'react'

export default function AdminReport() {
    return (
        <div className='lg:flex items-center space-y-2 lg:space-x-16 2xl:pl-20'>
            <div className="w-[280px] sm:w-[571px] lg:w-[40vw] h-[367px] bg-white rounded-[17px] border border-zinc-300 px-4 py-3 sm:py-6 shadow-xl" >
                <h1 className="text-black text-base sm:text-[22px] font-medium  ">Total Campaigns</h1>
                <div className="pt-2 sm:pt-6">
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                    <div className="flex items-center justify-between py-[9px] ">
                        <p className="text-black text-xs font-light  ">Being Edited</p>
                        <p className="text-black text-xs font-light  pr-16 sm:pr-60 lg:pr-40 xl:pr-60">Running</p>
                    </div>
                </div>
            </div>
            <div className="w-[280px] sm:w-[571px] lg:w-[40vw] h-[367px] bg-white rounded-[17px] border border-zinc-300 px-4 py-3 sm:py-6 shadow-xl" >
                <div className="flex items-center justify-between">
                    <h1 className="text-black text-base sm:text-[22px] font-medium">Total Earned</h1>
                    <p className='text-indigo-500 text-lg sm:text-[22px] font-medium'>$0.00</p>
                </div>
                <div className="pt-6">
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px] border-b">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                    <div className=" py-[9px]">
                        <p className="text-black text-xs font-light  ">Funding Sought Total</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

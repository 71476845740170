import React from 'react'

export default function SmallCard(props) {
    return (
        <div>
            <div className="flex flex-col bg-white border border-zinc-300 items-center justify-center w-full lg:w-[14.2vw] sm:w-[33vw] h-56 lg:h-[204.44px] rounded-[30px]">
                <img src={props.img} />
                <p className='text-sm'>{props.text}</p>
            </div>
        </div>

    )
}

import React from 'react'
import searchIcon from '../assets/icons/Search.png'

export default function Search() {
    return (
        <div className='relative ml-30 sm:ml-0'>
            <input type="search" placeholder='Search for campaign' className="py-3 rounded-[13px] border border-black focus:outline-none focus:border-blue-600 text-sm font-light pl-9 sm:pl-12" />
            <img src={searchIcon} alt="searchIcon" className='absolute top-3 left-3 sm:left-5' />
        </div>
    )
}

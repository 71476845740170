import React, { useState } from 'react';
import AdminReport from '../../components/AdminReport';
import AdminCompaign from '../../components/AdminCompaign';
import SideBar from '../../components/SideBar';
import DashboardHeader from '../../components/DashboardHeader';

const Administration = () => {
  const [Type, setType] = useState("Reports");

  const handleTypeChange = (newType) => {
    setType(newType);
  };

  return (
    <div className='mx-4 '>
      {/* Header Part */}
      <div className="">
        <DashboardHeader />
      </div>
      <div className="ml-20 sm:ml-32">
        <div className="text-black text-3xl sm:text-[40px] font-medium pb-4">Administrator Dashboard</div>
        <div className="flex items-center justify-start space-x-2 py-2">
          <div
            onClick={() => handleTypeChange("Reports")}
            className={`cursor-pointer w-[122px] h-10 ${Type === "Reports" ? "bg-indigo-500 text-white" : "bg-white text-indigo-500"
              } rounded-[9px] text-xs font-normal border flex items-center justify-center`}
          >
            Reports
          </div>
          <div
            onClick={() => handleTypeChange("Compaign")}
            className={`cursor-pointer w-[114px] h-10 rounded-[9px] border border-indigo-500 text-xs font-normal flex items-center justify-center ${Type === "Compaign" ? "bg-indigo-500 text-white" : "bg-white text-indigo-500"
              }`}
          >
            Campaign
          </div>
        </div>
      </div>
      <div className="ml-16 sm:ml-32 my-8">
        {Type === "Reports" ? (
          <AdminReport></AdminReport>
        ) : (
          Type === "Compaign" && <AdminCompaign></AdminCompaign>
        )}
      </div>
      <SideBar></SideBar>
    </div>
  );
};

export default Administration;

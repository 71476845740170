import React, { useState } from 'react'
import Button from '../../components/Button'
import SideBar from '../../components/SideBar'
import Notifications from '../../components/Notifications'
import DashboardHeader from '../../components/DashboardHeader'

export default function NotificationSettings() {
    return (
        <div>
            <div>
                <DashboardHeader />
            </div>
            <div className="absolute top-0 left-0 lg:left-6">
                <SideBar></SideBar>
            </div>
            <div className="NotificationSetting ml-[20vw] sm:ml-[10vw] mr-[8vw]">
                <h1 className=" text-black text-[30px] sm:text-[40px] font-medium">Campaign</h1>
                <div className="flex gap-2 sm:justify-end">
                    <Button text="Discard Changing" bgcolor="white" textcolor="indigo-500" />
                    <Button text="Save" bgcolor="indigo-500" textcolor="white" />
                </div>
                <form action="#">
                    <div className="">
                        <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Campaign Title</label>
                        <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='Your Campaign Title' />
                    </div>
                    <div className="mt-2 sm:mt-5">
                        <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Campaign Description</label>
                        <textarea className='h-[23vh] rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='description' placeholder='Provide a brief and compelling description of your campaign' />
                    </div>
                    <div className="mt-2 sm:mt-7">
                        <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Campaign Category</label>
                        <textarea className='h-[10vh] rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='description' placeholder='Specify the category of your campaign, e.g., Fundraising, Awareness, Creative Project, etc.' />
                    </div>
                    <div className="mt-2 sm:mt-6">
                        <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Campaign Goal</label>
                        <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='Target Funds: $________' />
                    </div>
                    <div className="flex items-center justify-center w-full my-2 sm:my-6 gap-4">
                        <div className="w-1/2">
                            <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>Start Date</label>
                            <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='MM/DD/YYYY' />
                        </div>
                        <div className="w-1/2">
                            <label className='block text-gray-900 text-lg font-medium leading-loose' htmlFor='title'>End Date</label>
                            <input className='rounded-lg border border-stone-300 w-full text-zinc-500 text-sm font-normal ' type="text" name='title' placeholder='MM/DD/YYYY' />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
const CheckboxWithLabel = ({ label = "", description = "" }) => {
    return (
        <div className="flex items-center gap-4">
            <div className="">
                <input name={label} className='focus:outline-none rounded focus:border-none w-6 h-6' type="checkbox" />
            </div>
            <div className="">
                <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
                <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
            </div>
        </div>
    );
};
const RadioWithLabel = ({ label = "", description = "" }) => {
    return (
        <div className="flex items-center gap-4">
            <div className="">
                <input name={label} className='focus:outline-none focus:border-none w-6 h-6' type="radio" />
            </div>
            <div className="">
                <h1 className="text-zinc-800 text-sm font-semibold leading-tight">{label}</h1>
                <p className="text-slate-500 text-xs font-medium leading-[18px]">{description}</p>
            </div>
        </div>
    );
};
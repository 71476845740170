import React from 'react'
import SideBar from '../../components/SideBar'
import Mydash from '../../components/Mydash'
import img from '../../assets/images/category-detail-img.png'
import DashboardHeader from '../../components/DashboardHeader'

export default function MyDashboard() {
    return (
        <div className='relative'>
            <div className="">
                <DashboardHeader />
            </div>
            <div className="absolute top-0 left-0 lg:left-6">
                <SideBar></SideBar>
            </div>
            <div className="absolute top-18 sm:top-20  xl-top-0 lg:left-28 sm:left-20 left-14">
                <Mydash></Mydash>
            </div>
            <div className='absolute top-[1150px] left-20 sm:top-[12vh] sm:left-[55%] lg:left-[45vw] xl:left-[35vw] xl:mr-10'>
                <div className="text-black text-[40px] font-medium ">Steeler</div>
                <div className="text-red-500 text-xs font-light">This campaign is not approved yet</div>
                <div className="flex lg:items-center flex-col lg:flex-row lg:justify-between py-4">
                    <div className="flex">
                        <div className="z-20 shadow-2xl px-4 py-2 bg-indigo-500 rounded-[9px] text-white text-xs font-normal flex items-center justify-center" >
                            Raise mode
                        </div>
                        <div className="mx-2"></div>
                        <div className="-ml-2 px-4 py-2 bg-white rounded-[9px] text-black text-xs font-normal flex items-center justify-center border" >
                            Keep it All
                        </div>
                    </div>
                    <div className="flex pt-2 lg:pt-0 gap-2">
                        <div className="px-4 py-2 bg-indigo-500 rounded-[9px] text-white text-xs font-normal flex items-center justify-center" >
                            Share Compaign
                        </div>
                        <div className="px-4 py-2 bg-white rounded-[9px] text-black text-xs font-normal flex items-center justify-center border  border-indigo-500" >
                            Embed Code
                        </div>
                    </div>
                </div>
                <div className="lg:px-2 flex xl:w-full flex-col xl:flex-row bg-white rounded-[17px] border border-zinc-300 " >
                    <div className="py-4 px-4 xl:w-2/5 ">
                        <img className='w-full lg:h-[30vh] xl:h-auto max-h-[350px] rounded-[14px]' src={img} alt="img" />
                    </div>
                    <div className="flex flex-col px-2 xl:w-3/5 py-4">
                        <div className="text-black text-lg xl:text-2xl font-light pb-2">About this campaign</div>
                        <div className=" text-neutral-400 text-[13px] xl:text-lg- font-light">Voluptatibus sit repellat vel eligendi quibusdam et quam itaque  sectetur. Quisquam quam hic sunt ipsam  iunt aut minus eaque. Autem eos. laboriosam. Sequi ratione pariat non pariatur dignissimos. Cumque itaque consequatur alias quasi. Labore tiae fugiat dolores rerum explicabo rerum eum provident.</div>
                        <div className="flex h-2 mb-4 overflow-hidden text-xs bg-gray-200 rounded my-1  lg:w-auto">
                            <div style={{ width: `50%` }} className="flex flex-col justify-center text-center text-white bg-indigo-500 shadow-none whitespace-nowrap"></div>
                        </div>
                        <div className="flex flex-col lg:flex-row">
                            <div className="">
                                <div className="py-2 2xl:py-4 2xl:px-10 bg-indigo-500 rounded-2xl text-white text-lg 2xl:text-2xl font-normal  flex items-center justify-center" >
                                    Contribute
                                </div>
                                <div className="text-black text-[10px] 2xl:text-base font-light mt-2">Continuous Campaign - No End Date</div>
                            </div>
                            <div className="grid grid-cols-3 sm:grid-cols-2 lg:gap-4 lg:px-8 pt-6 sm:pt-4 lg:pt-0">
                                <div className="">
                                    <div className="text-indigo-500 text-xl 2xl:text-2xl font-medium  ">$0.00 USD</div>
                                    <div className="text-neutral-400 text-[10px] font-light  ">Raised in</div>
                                </div>
                                <div className="">
                                    <div className="text-indigo-500 text-xl 2xl:text-2xl font-medium  ">0</div>
                                    <div className="text-neutral-400 text-[10px] font-light  ">Backers</div>
                                </div>
                                <div className="">
                                    <div className="text-indigo-500 text-xl 2xl:text-2xl font-medium  ">0%</div>
                                    <div className="text-neutral-400 text-[10px] font-light  ">Funded of $0.00 Goal</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center flex-wrap lg:flex-nowrap space-x-1 space-y-1 lg:space-x-2 py-4 lg:mt-0">
                    <div className="px-8 py-2 bg-indigo-500 bg-opacity-20 rounded-[9px] text-indigo-500 text-[11px] font-normal flex items-center justify-center" >FAQs</div>
                    <div className="px-8 py-2 bg-indigo-500 bg-opacity-20 rounded-[9px] text-indigo-500 text-[11px] font-normal flex items-center justify-center" >Backers</div>
                    <div className="px-8 py-2 bg-indigo-500 bg-opacity-20 rounded-[9px] text-indigo-500 text-[11px] font-normal flex items-center justify-center" >Updates</div>
                    <div className="px-8 py-2 bg-indigo-500 bg-opacity-20 rounded-[9px] text-indigo-500 text-[11px] font-normal flex items-center justify-center" >Comments</div>
                </div>
            </div>
        </div>
    )
}

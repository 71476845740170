import React, { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

const ProgressBar = () => {
  const [progressValue, setProgressValue] = useState(30); // Initial progress value

  const updateProgress = () => {
    const newValue = Math.random() * 100; // You can replace this with your logic to update the value
    setProgressValue(newValue);
  };

  return (
    <div style={{marginBottom:"17px",marginTop:"4px"}}> 
      <LinearProgress variant="determinate" value={progressValue} sx={{ width: '100%' ,height:"11px",borderRadius:"15px"}} />
      {/* <p>Progress: {progressValue}%</p> */}
      {/* <Button onClick={updateProgress} variant="contained" color="primary">
        Update Progress
      </Button> */}
    </div>
  );
};

export default ProgressBar;

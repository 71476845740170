import React from "react";
import Header from "../../components/Header";
import SmallCard from "../../components/SmallCard";
import BigCard from "../../components/BigCard";
import SideBar from "../../components/SideBar";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import maps from "../../assets/icons/maps.png";
import esports from "../../assets/icons/esports.png";
import appgames from "../../assets/icons/appgames.png";
import gamingfear from "../../assets/icons/gamingfear.png";
import socialgaming from "../../assets/icons/socialgaming.png";
import DashboardHeader from "../../components/DashboardHeader";

const dropDownitems = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const Login = () => {
  return (
    <>
      <div className="relative">
        <div className="relative">
          <DashboardHeader />
        </div>
        <div className="sm:flex items-center justify-between">
          <div className="ml-20 sm:ml-28 lg:ml-40 mt-5 sm:mt-12 lg:mt-0">
            <div>
              <span className="text-black text-3xl sm:text-[50px] font-medium  ">
                Welcome to{" "}
              </span>
              <span className="text-indigo-500 text-[50px] font-medium  ">
                Gamestart
              </span>
            </div>
            <p className="text-black text-2xl sm:text-[35px] font-light">
              Explore new games and gaming projects
            </p>
          </div>
          {/* <div className=' mt-5 mb-5 sm:mb-0 sm:mt-0 w-[269px] h-[59px] bg-indigo-500 rounded-2xl relative sm:ml-0 ml-20 sm:mr-5 '>
            <Dropdown
              menu={{
                dropDownitems,
              }}
              trigger={['click']}
            >
         
                <Space className='text-white text-lg font-light absolute top-3 left-3 '>
                  Fearured
                  <DownOutlined className='pl-32 sm:pl-5 lg:pl-0' />
                </Space>
            
            </Dropdown>
          </div> */}
          <div className="mt-5 mb-5 sm:mb-0 sm:mt-0 w-[269px] h-[59px] bg-indigo-500 rounded-2xl relative sm:ml-0 ml-20 sm:mr-5">
            <Dropdown menu={{ dropDownitems }} trigger={["click"]}>
              <>
                <div className="text-white pt-1 text-lg font-light absolute top-3 left-3 cursor-pointer">
                  Featured
                  <DownOutlined className="pl-32 sm:pl-11 lg:pl-24 xl:pl-36" />
                </div>
              </>
            </Dropdown>
          </div>
        </div>
        <div className="pl-16 sm:pl-0 flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap lg:items-center lg:justify-around lg:mb-7 sm:mt-10 bg-slate-900 border-t">
          <SmallCard img={appgames} text="App Games"></SmallCard>
          <SmallCard img={socialgaming} text="Social Gaming"></SmallCard>
          <SmallCard img={gamingfear} text="Gaming Fear"></SmallCard>
          <SmallCard img={esports} text="ESports"></SmallCard>
          <SmallCard img={maps} text="MODS/MAPS/ADDONS"></SmallCard>
          <SmallCard img={appgames} text="App Games"></SmallCard>
          <SmallCard text="Other Gaming"></SmallCard>
        </div>
        {/* <div className="flex items-center justify-center mb-10 space-x-10"> */}
        <div className="pl-16 grid space-y-2 my-10 lg:mt-0 sm:gap-2 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 lg:pl-10">
          <BigCard></BigCard>
          <BigCard></BigCard>
          <BigCard></BigCard>
          <BigCard></BigCard>
          <BigCard></BigCard>
          <BigCard></BigCard>
          <BigCard></BigCard>
          <BigCard></BigCard>
        </div>
        <div className="flex items-center justify-center mb-10 text-indigo-500 text-[22px] font-medium cursor-pointer">
          View All
        </div>
      </div>
      <div className="absolute top-0 left-0 lg:left-6">
        <SideBar></SideBar>
      </div>
    </>
  );
};

export default Login;

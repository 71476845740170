import React from 'react'
import Header from "../components/Header";
import appgames from "../assets/icons/appgames.png";
import esports from "../assets/icons/esports.png";
import games from "../assets/icons/games.png";
import maps from "../assets/icons/maps.png";
import socialgaming from "../assets/icons/socialgaming.png";
import section4Img from "../assets/images/section4Img.png";
import gamingfear from "../assets/icons/gamingfear.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from "@mui/icons-material/Search";
import vector from "../assets/icons/Vector.png";
import categoryDetail from "../assets/images/category-detail-img.png";

import "../styles/NewCampaign.css";
import ProgressBar from "../components/ProgressBar";
import FooterTop from "../components/FooterTop";
import Footer from "../components/Footer";


const NewCompaign = () => {
  return (
    <>
    
    <div className="main-section-campaign">
        <Header />
        <div className="campaign-mid-text">
          <div className="box1">
            <p className="main-heading-text">
              {" "}
              Start Your Own <span>Campaign</span>
            </p>
            {/* <img src={vector} alt="" className="vector" /> */}
          </div>
          <div className="box3">
            <div className="search-label">
              <SearchIcon className="search-icon" />
              <input
                type="search"
                id="search"
                className="search-input"
                placeholder="Search for campaign"
              />
            </div>
          </div>

          <button className="start-compaign-btn">Start Campaign</button>
        </div>
       
      </div>



      <FooterTop/>
      <Footer/>
    </>
  )
}

export default NewCompaign
